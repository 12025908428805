import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(" Editar dados ")]),_c(VCardText,[_c(VTextField,{attrs:{"label":"Setor","outlined":"","dense":""},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}}),_c(VTextField,{attrs:{"label":"Código Self","type":"number","outlined":"","dense":""},model:{value:(_vm.data.self_id),callback:function ($$v) {_vm.$set(_vm.data, "self_id", $$v)},expression:"data.self_id"}}),_c(VSelect,{attrs:{"items":_vm.statusSelect,"label":"Status","outlined":"","dense":""},model:{value:(_vm.data.status),callback:function ($$v) {_vm.$set(_vm.data, "status", $$v)},expression:"data.status"}}),_c('div',{staticClass:"d-flex justify-end mt-4"},[_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cancelar ")]),_c(VBtn,{staticClass:"ml-4",attrs:{"color":"info"},on:{"click":function($event){return _vm.sendModalData()}}},[(!_vm.isLoading)?_c('span',[_vm._v("Enviar ")]):_c(VProgressCircular,{attrs:{"color":"white","indeterminate":""}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }