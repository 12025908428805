<template>
  <div>
    <v-card>
      <v-card-title class="pt-0 pb-0">
        <span>
          Setores
        </span>

        <v-spacer />

        <v-text-field
          v-model="filterData"
          label="Buscar por nome"
          class="mt-6 mr-4"
          outlined
          dense
          @keyup.enter="findFilterResult()"
        />

        <v-btn
          color="info"
          outlined
          @click="openModal('create')"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          Novo
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="listOfFilteredItems"
        :loading="isLoading"
        loading-text="Carregando dados..."
      >
        <template
          v-slot:no-data
        >
          <span>Sem informações para exibir</span>
        </template>

        <template v-slot:item.cpf="{item}">
          {{ formattedCpfNumber(item.cpf) }}
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ dateFormat(item.created_at) }}
        </template>

        <template v-slot:item.edit="{ item }">
          <v-icon
            color="info"
            @click="openModal('details', item)"
          >
            {{ icons.mdiPencil }}
          </v-icon>
        </template>

        <template v-slot:item.delete="{ item }">
          <v-icon
            color="error"
            @click="deleteRegister(endpointDelete, item.id)"
          >
            {{ icons.mdiDelete }}
          </v-icon>
        </template>
      </v-data-table>

      <v-dialog
        v-model="showSectorModal"
        width="700px"
      >
        <SectorModal
          @updatedTable="updatedTable()"
          @close="showSectorModal = false"
        >
        </SectorModal>
      </v-dialog>

      <v-dialog
        v-model="showSectorDetails"
        width="700px"
      >
        <SectorDetails
          :key="dataDetails.id"
          :data="dataDetails"
          @close="showSectorDetails = false"
        ></SectorDetails>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiDelete, mdiEye, mdiFilter, mdiPencil, mdiPlaylistPlus,
} from '@mdi/js'
import SectorDetails from './SectorDetails.vue'
import SectorModal from './SectorModal.vue'

export default {
  components: {
    SectorModal,
    SectorDetails,
  },

  mixins: [formatters, messages],

  data() {
    return {
      filterData: '',
      dataDetails: {},

      endpointDelete: '/api/v1/records/sector/destroy',

      headers: [
        {
          text: 'NOME', value: 'name', sortable: false, align: 'left',
        },
        {
          text: 'COD. SELF', value: 'self_id', sortable: false, align: 'center',
        },
        {
          text: 'STATUS', value: 'status', sortable: false, align: 'center',
        },
        {
          text: 'DATA DE CRIAÇÃO', value: 'created_at', sortable: false, align: 'center',
        },
        {
          text: 'EDITAR', value: 'edit', sortable: false, align: 'center',
        },
        {
          text: 'EXCLUIR', value: 'delete', sortable: false, align: 'center',
        },
      ],

      itemsTable: [],
      listOfFilteredItems: [],

      isLoading: false,
      showSectorModal: false,
      showSectorDetails: false,

      icons: {
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiFilter,
        mdiPlaylistPlus,
      },
    }
  },

  watch: {
    filterData() {
      this.findFilterResult()
    },
  },

  created() {
    this.getItemsTable()
  },

  methods: {
    async getItemsTable() {
      this.isLoading = true

      await axiosIns.get('/api/v1/records/sector/index').then(response => {
        this.itemsTable = response.data.data
        this.listOfFilteredItems = response.data.data
      }).catch(error => {
        this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, icon: 'error' })
      }).finally(() => {
        this.isLoading = false
      })
    },

    findFilterResult() {
      const { itemsTable, filterData } = this
      const filterDataLower = filterData.toLowerCase()

      this.listOfFilteredItems = itemsTable.filter(({
        name,
      }) => {
        const lowerName = name.toLowerCase()

        return (
          lowerName.includes(filterDataLower)
        )
      })
    },

    updatedTable() {
      this.getItemsTable()
    },

    openModal(type, data = {}) {
      const modal = {
        create: () => { this.showSectorModal = true },
        details: () => {
          this.showSectorDetails = true
          this.dataDetails = data
        },
      }

      if (type in modal) {
        modal[type]()

        return
      }

      console.error(`Invalid modal type: ${type}`)
    },
  },
}
</script>
