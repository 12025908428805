<template>
  <v-card>
    <v-card-title class="justify-center">
      Editar dados
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="data.name"
        label="Setor"
        outlined
        dense
      />

      <v-text-field
        v-model="data.self_id"
        label="Código Self"
        type="number"
        outlined
        dense
      />

      <v-select
        v-model="data.status"
        :items="statusSelect"
        label="Status"
        outlined
        dense
      />

      <div class="d-flex justify-end mt-4">
        <v-btn
          color="error"

          @click="closeModal()"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="info"
          class="ml-4"
          @click="sendModalData()"
        >
          <span
            v-if="!isLoading"
          >Enviar
          </span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'

export default {
  mixins: [formatters, messages],

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,

      statusSelect: [
        'Ativado',
        'Desativado',
      ],
    }
  },

  methods: {
    async sendModalData() {
      const {
        data: {
          // eslint-disable-next-line camelcase
          id, name, self_id, status,
        },
      } = this

      this.isLoading = true

      const body = {
        name: name.toUpperCase(),
        self_id,
        status: status.toUpperCase(),
      }

      await axiosIns.put(`/api/v1/records/sector/update/${id}`, body)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Informações atualizadas',
            text: 'alterado com sucesso',
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao atualizar dados',
            text: error,
          })
        }).finally(() => {
          this.isLoading = false
          this.closeModal()
        })
    },
  },
}
</script>
<style scoped>
/* Retira o incrementador padrão dos inputs do tipo "number"*/
/*Remove incrementer from numeric type inputs */
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
