<template>
  <v-card>
    <v-card-title class="justify-center">
      Cadastrar Setor
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-text-field
          v-model="sectorName"
          :rules="[rules.required]"
          label="Setor"
          outlined
          dense
        />

        <v-text-field
          v-model="selfId"
          label="Código Self"
          type="number"
          outlined
          dense
        />

        <v-autocomplete
          v-model="selectedStatus"
          :items="status"
          :rules="[rules.required]"
          label="Status"
          outlined
          dense
        />
      </v-form>

      <div class="d-flex justify-end mt-4">
        <v-btn
          color="error"
          @click="closeModal()"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="info"
          class="ml-4"
          @click="sendModalData()"
        >
          <span
            v-if="!isLoading"
          >Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'

export default {
  mixins: [formatters, messages],

  data() {
    return {
      selfId: '',
      sectorName: '',
      selectedStatus: '',

      isLoading: false,

      status: [
        'Ativado',
        'Desativado',
      ],
    }
  },

  methods: {
    async sendModalData() {
      const isValid = this.$refs.form.validate()
      const { sectorName, selfId, selectedStatus } = this

      if (!isValid) {
        this.showMessage({ title: 'Formulário inválido', text: 'Verifique os campos obrigatórios' })

        return
      }

      this.isLoading = true

      const body = {
        name: sectorName.toUpperCase(),
        self_id: selfId,
        status: selectedStatus.toUpperCase(),
      }

      await axiosIns
        .post('api/v1/records/sector/store', body)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Informações atualizadas',
            text: 'alterado com sucesso',
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao atualizar dados',
            text: error,
          })
        })
        .finally(() => {
          this.isLoading = false
          this.closeModal()
        })
    },

    resetForm() {
      this.$refs.form.reset()
    },
  },
}
</script>

<style>
/* Retira o incrementador padrão dos inputs do tipo "number"*/
/*Remove incrementer from numeric type inputs */

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
